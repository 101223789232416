import React, { useState, useContext, useEffect } from 'react'
import { Layout as AntdLayout } from 'antd'

import { useUser } from 'app/auth'
import { Loading } from 'components/Loading'
import { PortalContext } from 'components/App/App'
import SiderMenuManagers from '../SiderMenuManagers'
import { SupplierContextProvider } from 'components/SupplierContext/provider'

import styles from './Layout.module.scss'

type LayoutProps = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, shouldWaitUser = false }) => {
  const [user] = useUser()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/common')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (shouldWaitUser && !user) return <Loading />

  return (
    <SupplierContextProvider>
      <ManagerLayout>{children}</ManagerLayout>
    </SupplierContextProvider>
  )
}

const ManagerLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/managers')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AntdLayout hasSider className={styles.layout}>
      <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242}>
        <SiderMenuManagers collapsed={collapsedMainMenu} />
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Content className={styles.content}>{children}</AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
