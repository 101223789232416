import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { store } from 'app/store'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type VerifyEmailArgs = {
  verifyEmailToken: string
}

type FetchClaimArgOptionsArgs = {
  searchText?: string
  claim: Pto.Auth.Claim
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Users', 'Claims', 'ClaimArgs'],
  endpoints: (builder) => ({
    activateUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/activate/${userId}`,
        method: 'PATCH'
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    banUser: builder.mutation<boolean, Pto.Users.BanUser>({
      query: (body) => ({
        url: '/users/ban',
        body,
        method: 'PATCH'
      })
    }),
    claimArgOptions: builder.query<Pto.Option[], FetchClaimArgOptionsArgs>({
      query: ({ claim, searchText }) => ({
        url: `/auth/get-claim-arg-options`,
        params: { searchText, claim }
      }),
      providesTags: (claimArgOptionsData, _error, _args) =>
        getProvidedTags(
          'ClaimArgs',
          'OPTIONS',
          claimArgOptionsData?.map((item) => item.value)
        )
    }),
    conversationSignUp: builder.mutation<Pto.Users.Login, Pto.Users.CustomerSignUpViaConversation>({
      query: ({ email, fullName, password, inviteLink }) => ({
        url: '/users/customer-sign-up-via-conversation',
        body: { email, fullName, password, inviteLink },
        method: 'POST'
      })
    }),
    createUser: builder.mutation<boolean, Pto.Users.CreateUser>({
      query: (body) => ({
        url: '/users/create',
        body,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    changeAvatar: builder.mutation<void, Pto.Users.ChangeAvatar>({
      query: (body) => ({
        url: '/users/change-avatar',
        body,
        method: 'PATCH'
      })
    }),
    deactivateUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/deactivate/${userId}`,
        method: 'PATCH'
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    getClaims: builder.query<Pto.Auth.ActionClaim[], void>({
      query: () => ({
        url: '/users/get-claims'
      }),
      providesTags: (claimsData, _error, _args) =>
        getProvidedTags(
          'Claims',
          'LIST',
          claimsData?.map((item) => item.claim)
        )
    }),
    isInviteLinkUsed: builder.query<Pto.Conversations.ConversationParticipantLink, string>({
      query: (link: string) => ({
        url: `/conversations/is-invite-link-used/${link}`,
        method: 'GET'
      })
    }),
    login: builder.mutation<Pto.Auth.Session, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: '/auth/login',
        body: { email, password },
        method: 'POST'
      })
    }),
    manualVerifyEmail: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/users/manual-verify-email/${userId}`,
        method: 'PATCH'
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }]
    }),
    requestResetPassword: builder.mutation<void, Pto.Users.RequestResetPassword>({
      query: ({ email }) => ({
        url: `/users/generate-reset-password-token`,
        body: { email },
        method: 'PATCH'
      })
    }),
    resetPassword: builder.mutation<void, Pto.Users.ResetPassword>({
      query: (resetPassword) => ({
        url: `/users/reset-password`,
        body: resetPassword,
        method: 'PATCH'
      })
    }),
    setClaims: builder.mutation<boolean, Pto.Users.SetUserClaims>({
      query: (body) => ({
        url: '/users/set-claims',
        body,
        method: 'PATCH'
      })
    }),
    setRoles: builder.mutation<boolean, Pto.Users.SetUserRole>({
      query: (body) => ({
        url: '/users/set-roles',
        body,
        method: 'PATCH'
      })
    }),
    unbanUser: builder.mutation<boolean, Pto.Users.UnbanUser>({
      query: (body) => ({
        url: '/users/unban',
        body,
        method: 'PATCH'
      })
    }),
    userList: builder.query<Pto.Users.List, Pto.Users.UserListQuery>({
      query: ({ searchText, page, size }) => ({
        url: '/users',
        params: { searchText, page, size }
      }),
      providesTags: (userListData, _error, _args) =>
        getProvidedTags(
          'Users',
          'LIST',
          userListData?.items.map((item) => item.id)
        )
    }),
    userOptions: builder.query<Pto.Users.User[], Pto.Users.UserOptionsQuery>({
      query: ({ searchText, userRole, page, size }) => ({
        url: '/users/options',
        params: { searchText, userRole, page, size }
      }),
      providesTags: (userOptionsData, _error, _args) =>
        getProvidedTags(
          'Users',
          'OPTIONS',
          userOptionsData?.map((item) => item.id)
        )
    }),
    user: builder.query<Pto.Users.UserDetails | null, string>({
      query: (userId) => ({
        url: `/users/${userId}`
      }),
      providesTags: (_result, _error, userId) => getProvidedTags('Users', userId)
    }),
    verifyEmail: builder.mutation<boolean, VerifyEmailArgs>({
      query: (body) => ({
        url: '/users/verify-email',
        body,
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'User') {
    store.dispatch(usersApi.util.invalidateTags([{ type: 'Users', id: payload.entityId }]))
  }
}

export const subscribeToUserEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromUserEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useActivateUserMutation,
  useBanUserMutation,
  useChangeAvatarMutation,
  useClaimArgOptionsQuery,
  useConversationSignUpMutation,
  useCreateUserMutation,
  useDeactivateUserMutation,
  useLazyGetClaimsQuery,
  useLazyIsInviteLinkUsedQuery,
  useLoginMutation,
  useManualVerifyEmailMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useSetClaimsMutation,
  useSetRolesMutation,
  useUnbanUserMutation,
  useUserListQuery,
  useUserOptionsQuery,
  useUserQuery,
  useVerifyEmailMutation
} = usersApi
