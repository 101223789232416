import { Pto } from '@merchx-v3/pto'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

export const productsApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    productList: builder.query<Pto.ProductsV2.List, Pto.ProductsV2.ProductV2ListQuery>({
      query: (params) => ({
        url: `/products-v2`,
        params
      }),
      providesTags: (productListData, _error, _args) =>
        getProvidedTags(
          'Products',
          'LIST',
          productListData?.items.map((item) => item.id)
        )
    }),
    productOptions: builder.query<Pto.Option[], Pto.ProductsV2.ProductOptionsQuery>({
      query: (params) => ({
        url: `/products-v2/options`,
        params
      }),
      providesTags: () => getProvidedTags('Products', 'OPTIONS')
    }),
    product: builder.query<Pto.ProductsV2.Product, string>({
      query: (productId) => ({
        url: `/products-v2/${productId}`
      }),
      providesTags: (_result, _error, productId) => getProvidedTags('Products', productId)
    }),
    createProduct: builder.mutation<string, Pto.ProductsV2.CreateProduct>({
      query: (createProductDto) => ({
        url: 'products-v2',
        body: createProductDto,
        method: 'POST'
      }),
      invalidatesTags: getProvidedTags('Products', 'LIST')
      //  ['Products']
    }),
    changeImageVaryBy: builder.mutation<void, Pto.ProductsV2.ChangeProductAttributeVaryBy & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/image-vary-by`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changePriceVaryBy: builder.mutation<void, Pto.ProductsV2.ChangeProductAttributeVaryBy & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/price-vary-by`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeSkuVaryBy: builder.mutation<void, Pto.ProductsV2.ChangeProductAttributeVaryBy & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/sku-vary-by`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeWeightVaryBy: builder.mutation<void, Pto.ProductsV2.ChangeProductAttributeVaryBy & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/weight-vary-by`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeFulfillmentVaryBy: builder.mutation<void, Pto.ProductsV2.ChangeProductAttributeVaryBy & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/fulfillment-vary-by`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    addAttributeValue: builder.mutation<void, Pto.ProductsV2.AddAttributeValue & { productId: string; attributeId: string }>({
      query: ({ productId, attributeId, ...body }) => ({
        url: `products-v2/${productId}/attribute/${attributeId}/value`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeAttributeValueData: builder.mutation<void, Pto.ProductsV2.ChangeAttributeValueData & { productId: string; attributeId: string }>({
      query: ({ productId, attributeId, ...body }) => ({
        url: `products-v2/${productId}/attribute/${attributeId}/value`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeAttributeValue: builder.mutation<void, Pto.ProductsV2.RemoveAttributeValue & { productId: string; attributeId: string }>({
      query: ({ productId, attributeId, ...body }) => ({
        url: `products-v2/${productId}/attribute/${attributeId}/value`,
        body,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeDescription: builder.mutation<void, Pto.ProductsV2.ChangeDescription & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/description`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeBriefDescription: builder.mutation<void, Pto.ProductsV2.ChangeDescription & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/brief-description`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeDesignTemplateId: builder.mutation<void, { productId: string; designTemplateId: string }>({
      query: ({ productId, designTemplateId }) => ({
        url: `products-v2/${productId}/design-template-id/${designTemplateId}`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeDisplayName: builder.mutation<void, Pto.ProductsV2.ChangeDisplayName & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/display-name`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeFulfillmentSystem: builder.mutation<void, Pto.ProductsV2.ChangeFulfillmentSystem & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/fulfillment-system`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    setImageUrls: builder.mutation<void, Pto.ProductsV2.SetImageUrls & { productId: string; ruleId: string }>({
      query: ({ productId, ruleId, ...body }) => ({
        url: `products-v2/${productId}/images/${ruleId}/image-url`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    addImageRule: builder.mutation<void, Pto.ProductsV2.AddImageRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/image-rules`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeImageRule: builder.mutation<void, { productId: string; ruleId: string }>({
      query: ({ productId, ruleId, ...body }) => ({
        url: `products-v2/${productId}/image-rules/${ruleId}`,
        body,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeManufacturer: builder.mutation<void, Pto.ProductsV2.ChangeManufacturer & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/manufacturer`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeName: builder.mutation<void, Pto.ProductsV2.ChangeName & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/name`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeNumberOfFreePrintAreas: builder.mutation<void, Pto.ProductsV2.ChangeNumberOfFreePrintAreas & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/number-of-free-print-areas`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeMinimumOrderQuantity: builder.mutation<void, { productId: string; minimumOrderQuantity: number }>({
      query: ({ productId, minimumOrderQuantity }) => ({
        url: `products-v2/${productId}/minimum-order-quantity`,
        body: { minimumOrderQuantity },
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    addOption: builder.mutation<void, Pto.ProductsV2.AddOption & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/option`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeOption: builder.mutation<void, Pto.ProductsV2.ChangeOption & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/option`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeOption: builder.mutation<void, Pto.ProductsV2.RemoveOption & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/option`,
        body,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changePrintAreaPrice: builder.mutation<void, Pto.ProductsV2.ChangePrintAreaPrice & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/print-area-price`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeProcessingTemplateId: builder.mutation<void, { productId: string; processingTemplateId: string }>({
      query: ({ productId, processingTemplateId }) => ({
        url: `products-v2/${productId}/processing-template-id/${processingTemplateId}`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeSeoUrl: builder.mutation<void, Pto.ProductsV2.ChangeSeoUrl & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/seo-url`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeSizeChartId: builder.mutation<void, { productId: string; chartSizeId: string }>({
      query: ({ productId, chartSizeId }) => ({
        url: `products-v2/${productId}/chart-size-id/${chartSizeId}`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeStatus: builder.mutation<void, Pto.ProductsV2.ChangeStatus & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/status`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeTechniqueId: builder.mutation<void, { productId: string; techniqueId: string }>({
      query: ({ productId, techniqueId }) => ({
        url: `products-v2/${productId}/technique/${techniqueId}`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeBasePrice: builder.mutation<void, Pto.ProductsV2.ChangeBasePrice & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/base-price`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changePriceRule: builder.mutation<void, Pto.ProductsV2.ChangePriceRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/price-rules`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    addPriceRule: builder.mutation<void, Pto.ProductsV2.AddPriceRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/price-rules`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removePriceRule: builder.mutation<void, { productId: string; ruleId: string }>({
      query: ({ productId, ruleId }) => ({
        url: `products-v2/${productId}/price-rules/${ruleId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    changeWeightRule: builder.mutation<void, Pto.ProductsV2.ChangeWeightRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/weight-rules`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    addWeightRule: builder.mutation<void, Pto.ProductsV2.AddWeightRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/weight-rules`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeWeightRule: builder.mutation<void, { productId: string; ruleId: string }>({
      query: ({ productId, ruleId }) => ({
        url: `products-v2/${productId}/weight-rules/${ruleId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    addFulfillmentRule: builder.mutation<void, Pto.ProductsV2.AddFulfillmentRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/fulfillment-rules`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeFulfillmentRule: builder.mutation<void, { productId: string; ruleId: string }>({
      query: ({ productId, ruleId }) => ({
        url: `products-v2/${productId}/fulfillment-rules/${ruleId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    addFulfillmentRuleValue: builder.mutation<void, Pto.ProductsV2.AddFulfillmentRuleValue & { productId: string; ruleId: string }>({
      query: ({ productId, ruleId, ...body }) => ({
        url: `products-v2/${productId}/fulfillment-rules/${ruleId}`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeFulfillmentRuleValue: builder.mutation<void, Pto.ProductsV2.ChangeFulfillmentRuleValue & { productId: string; ruleId: string }>({
      query: ({ productId, ruleId, ...body }) => ({
        url: `products-v2/${productId}/fulfillment-rules/${ruleId}`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeFulfillmentRuleValue: builder.mutation<void, Pto.ProductsV2.RemoveFulfillmentRuleValue & { productId: string; ruleId: string }>({
      query: ({ productId, ruleId, ...body }) => ({
        url: `products-v2/${productId}/fulfillment-rules/${ruleId}/value`,
        body,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    addBulkDiscount: builder.mutation<void, Pto.ProductsV2.AddBulkDiscount & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/bulk-discounts`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeBulkDiscount: builder.mutation<void, { productId: string; quantity: number }>({
      query: ({ productId, quantity }) => ({
        url: `products-v2/${productId}/bulk-discounts/${quantity}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeBulkDiscount: builder.mutation<void, Pto.ProductsV2.ChangeBulkDiscount & { productId: string; quantity: number }>({
      query: ({ productId, quantity, ...body }) => ({
        url: `products-v2/${productId}/bulk-discounts/${quantity}`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    addSkuRule: builder.mutation<void, Pto.ProductsV2.AddSkuRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/sku-rules`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    removeSkuRule: builder.mutation<void, { productId: string; ruleId: string }>({
      query: ({ productId, ruleId }) => ({
        url: `products-v2/${productId}/sku-rules/${ruleId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeSkuRule: builder.mutation<void, Pto.ProductsV2.ChangeSkuRule & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/sku-rules`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    changeMainAttributeId: builder.mutation<void, { productId: string; attributeId: string }>({
      query: ({ productId, attributeId }) => ({
        url: `products-v2/${productId}/main-attribute-id/${attributeId}`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    changeQuantityAttributeId: builder.mutation<void, { productId: string; attributeId: string }>({
      query: ({ productId, attributeId }) => ({
        url: `products-v2/${productId}/quantity-attribute-id/${attributeId}`,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),
    uploadSizeChart: builder.mutation<void, Pto.ProductsV2.UploadSizeChart & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/upload-size-chart`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    }),

    changeCategory: builder.mutation<void, Pto.ProductsV2.ChangeCategory & { productId: string }>({
      query: ({ productId, ...body }) => ({
        url: `products-v2/${productId}/category`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { productId }) => getProvidedTags('Products', productId)
    })
  })
})

export const {
  useProductListQuery,
  useProductOptionsQuery,
  useProductQuery,
  useCreateProductMutation,

  useChangeImageVaryByMutation,
  useChangePriceVaryByMutation,
  useChangeSkuVaryByMutation,
  useChangeWeightVaryByMutation,
  useChangeFulfillmentVaryByMutation,
  useAddAttributeValueMutation,
  useChangeAttributeValueDataMutation,
  useRemoveAttributeValueMutation,
  useChangeDescriptionMutation,
  useChangeBriefDescriptionMutation,
  useChangeDesignTemplateIdMutation,
  useChangeDisplayNameMutation,
  useChangeFulfillmentSystemMutation,
  useSetImageUrlsMutation,
  useAddImageRuleMutation,
  useRemoveImageRuleMutation,
  useChangeManufacturerMutation,
  useChangeNameMutation,
  useChangeNumberOfFreePrintAreasMutation,
  useChangeMinimumOrderQuantityMutation,
  useAddOptionMutation,
  useChangeOptionMutation,
  useRemoveOptionMutation,
  useChangePrintAreaPriceMutation,
  useChangeProcessingTemplateIdMutation,
  useChangeSeoUrlMutation,
  useChangeSizeChartIdMutation,
  useChangeStatusMutation,
  useChangeBasePriceMutation,
  useChangePriceRuleMutation,
  useChangeTechniqueIdMutation,
  useAddPriceRuleMutation,
  useRemovePriceRuleMutation,
  useChangeWeightRuleMutation,
  useAddWeightRuleMutation,
  useRemoveWeightRuleMutation,
  useAddFulfillmentRuleMutation,
  useRemoveFulfillmentRuleMutation,
  useAddFulfillmentRuleValueMutation,
  useChangeFulfillmentRuleValueMutation,
  useRemoveFulfillmentRuleValueMutation,
  useAddBulkDiscountMutation,
  useChangeBulkDiscountMutation,
  useRemoveBulkDiscountMutation,
  useAddSkuRuleMutation,
  useChangeSkuRuleMutation,
  useRemoveSkuRuleMutation,
  useChangeMainAttributeIdMutation,
  useChangeQuantityAttributeIdMutation,
  useUploadSizeChartMutation,
  useChangeCategoryMutation
} = productsApi
