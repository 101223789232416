import React, { useState, useEffect, useContext } from 'react'
import { Layout as AntdLayout } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useUser } from 'app/auth'
import { Loading } from 'components/Loading'
import { PortalContext } from 'components/App/App'
import SiderMenuSuppliers from '../SiderMenuSuppliers'
import { AddSupplierCenterModal } from './AddSupplierCenterModal'
import { useSupplierContext } from 'components/SupplierContext/context'
import { SupplierContextProvider } from 'components/SupplierContext/provider'

import styles from './Layout.module.scss'

type LayoutProps = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, shouldWaitUser = false }) => {
  const [user] = useUser()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/common')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (shouldWaitUser && !user) return <Loading />

  return (
    <SupplierContextProvider>
      <SupplierLayout>{children}</SupplierLayout>
    </SupplierContextProvider>
  )
}

const SupplierLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)
  const [isAddSupplierCenterModalVisible, setIsAddSupplierCenterModalVisible] = useState(false)
  const { supplierId, supplierCenter, supplierCenters, setActiveSupplierCenter } = useSupplierContext()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/suppliers')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AntdLayout hasSider className={styles.layout}>
      <div className={styles.extraMenu}>
        {supplierCenters.map((item) => (
          <div key={item.id} className={item.id === supplierCenter.id ? styles.extraMenuItemActive : styles.extraMenuItem} onClick={() => setActiveSupplierCenter(item.id)}>
            {supplierCenter.name}
          </div>
        ))}
        <div className={styles.extraMenuItemAddNew} onClick={() => setIsAddSupplierCenterModalVisible(true)}>
          <PlusOutlined />
        </div>
        <AddSupplierCenterModal supplierId={supplierId} isOpened={isAddSupplierCenterModalVisible} onClose={() => setIsAddSupplierCenterModalVisible(false)} />
      </div>

      <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242}>
        <SiderMenuSuppliers collapsed={collapsedMainMenu} selectedSupplierCenter={supplierCenters.find((item) => supplierCenter.id === item.id)} />
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Content className={styles.content}>{children}</AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
