import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Role } from '@merchx-v3/pto'
import { Avatar, Badge, Dropdown, Menu, MenuProps } from 'antd'
import {
  AppstoreOutlined,
  BgColorsOutlined,
  BuildOutlined,
  CameraOutlined,
  LogoutOutlined,
  PictureOutlined,
  ProjectOutlined,
  RocketOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SkinOutlined,
  TeamOutlined,
  HighlightOutlined,
  UserOutlined
} from '@ant-design/icons'
import { AppRoutes } from '../Router'

import { useUser } from 'app/auth'
import { authProvider } from 'app/auth/auth-provider'
import { useConversation } from 'app/chat/context/context'
import { connector, PropsFromRedux } from './container'

import styles from './SiderMenu.module.scss'

const items: MenuProps['items'] = [
  {
    key: 'settings',
    label: <Link to={`./${AppRoutes.profile}`}>Profile</Link>
  },
  {
    key: 'switchInterface',
    label: <Link to="/">Switch interface</Link>
  }
]

type Props = PropsFromRedux & {
  collapsed: boolean
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

const iconStyle = { fontSize: 20, color: 'rgba(255, 255, 255, 0.85)' }

let menu: MenuItem[] = [
  getItem(<Link to={`./${AppRoutes.dashboard}`}>Dashboard</Link>, AppRoutes.dashboard, <AppstoreOutlined style={iconStyle} />),
  getItem('Customers', AppRoutes.customers, <TeamOutlined style={iconStyle} />, [
    getItem(<Link to={`./${AppRoutes.customers}`}>List</Link>, AppRoutes.customers),
    getItem(<Link to={`./${AppRoutes.customerReport}`}>Report</Link>, AppRoutes.customerReport)
  ]),
  getItem(<Link to={`./${AppRoutes.carts}`}>Carts</Link>, AppRoutes.carts, <ShoppingCartOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.ecomProducts}`}>Ecom Products</Link>, AppRoutes.ecomProducts, <SkinOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.products}`}>Products V2</Link>, AppRoutes.products, <SkinOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.projects}`}>Projects</Link>, AppRoutes.projects, <ProjectOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.designRequest}`}>Design Requests</Link>, AppRoutes.designRequest, <PictureOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.design}`}>Design</Link>, AppRoutes.design, <CameraOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.fulfillment}`}>Fulfillment</Link>, AppRoutes.fulfillment, <BgColorsOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.shipping}`}>Shipping</Link>, AppRoutes.shipping, <RocketOutlined style={iconStyle} />),
  getItem(<Link to={`./${AppRoutes.sagas}`}>Sagas</Link>, AppRoutes.sagas, <TeamOutlined style={iconStyle} />),
  getItem('Settings', AppRoutes.settings, <SettingOutlined style={iconStyle} />, [
    getItem('Plugins', AppRoutes.plugins, <BuildOutlined style={iconStyle} />, [
      getItem(<Link to={`./${AppRoutes.pluginFulfillmentList}`}>Plugin Fulfillment List</Link>, AppRoutes.pluginFulfillmentList),
      getItem(<Link to={`./${AppRoutes.pluginShippingList}`}>Plugin Shipping List</Link>, AppRoutes.pluginShippingList)
    ]),
    getItem(<Link to={`./${AppRoutes.suppliers}`}>Suppliers</Link>, AppRoutes.suppliers, <TeamOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.attributes}`}>Attributes</Link>, AppRoutes.attributes, <TeamOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.categories}`}>Categories</Link>, AppRoutes.categories, <TeamOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.techniques}`}>Techniques</Link>, AppRoutes.techniques, <HighlightOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.customGatewaySettings}`}>Custom Gateway Settings</Link>, AppRoutes.customGatewaySettings, <TeamOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.customGatewayCategories}`}>Custom Gateway Categories</Link>, AppRoutes.customGatewayCategories, <TeamOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.shipstationSettings}`}>Shipstation Settings</Link>, AppRoutes.customGatewaySettings, <TeamOutlined style={iconStyle} />),
    getItem(<Link to={`./${AppRoutes.users}`}>Users</Link>, AppRoutes.users, <TeamOutlined style={iconStyle} />)
  ])

  // getItem('Sales', AppRoutes.orders, <BarChartOutlined style={iconStyle} />, [getItem('Carts', '/images/carts'), getItem('Orders', '/sales/orders')]),
  // getItem('Payments', AppRoutes.payments, <DollarCircleOutlined style={iconStyle} />),
]

const SiderMenu = ({ collapsed, appVersion }: Props) => {
  const location = useLocation()
  const [user] = useUser()

  const { totalUnreadMessages } = useConversation()

  let menuItems = [] as MenuItem[]
  if (user?.roles.includes(Role.SystemAdmin)) {
    menuItems = menu
  }
  if (user?.roles.includes(Role.User)) {
    menuItems = menu
  }

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.userSection}>
          <div className={styles.avatar}>
            <Badge count={totalUnreadMessages}>
              <Avatar
                size={40}
                style={{ backgroundColor: '#87d068', border: 0 }}
                src={`/images/user-avatars/${user?.id || ''}?width=200&height=200`}
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge>
          </div>
          {!collapsed && (
            <div className={styles.userInfo}>
              <div className={styles.textWrapper}>
                <div className={styles.name}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</div>
                <div className={styles.email}>{user?.email}</div>
              </div>

              <Dropdown menu={{ items }} placement="bottomLeft">
                <SettingOutlined style={iconStyle} />
              </Dropdown>
            </div>
          )}
        </div>
        <Menu selectedKeys={[location.pathname]} mode="inline" theme="dark" items={menuItems} />
      </div>

      <div className={styles.logoutBox}>
        <div className={styles.logout} onClick={() => authProvider.signOut()}>
          <LogoutOutlined style={{ color: 'rgba(255, 255, 255, 0.85)' }} />
          {!collapsed && <p className={styles.logoutText}>Logout</p>}
        </div>
        {!collapsed && <p className={styles.version}> Version {appVersion}</p>}
      </div>
    </div>
  )
}

export default connector(SiderMenu)
