import { toast } from 'react-toastify'
import ReactQuill, { Quill } from 'react-quill'
import { EditOutlined } from '@ant-design/icons'
import React, { useEffect, useState, useRef } from 'react'
import { Button, Popconfirm, Typography } from 'antd'
// @ts-ignore
import quillEmoji from 'quill-emoji'

import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'

import styles from './EditableQuillEditorAntd.module.scss'

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji

Quill.register(
  {
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji
  },
  true
)

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [
      {
        color: [
          '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', 
          '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', 
          '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', 
          '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', 
          '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', 
          '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'
        ]
      },
      { background: [] }
    ],
    ['link', 'emoji'],
    ['clean']
  ],
  'emoji-toolbar': true,
  'emoji-textarea': true,
  'emoji-shortname': true
}

type Props = {
  title: string
  sourceValue: string
  onSave: (newValue: string) => Promise<boolean>
  limit?: number
}

const EditableQuillEditorAntd = ({ title, sourceValue, onSave, limit }: Props) => {
  const reactQuillRef = useRef<ReactQuill>(null)
  const [counter, setCounter] = useState<number>(0)
  const [value, setValue] = useState<string>(sourceValue)
  const [isConfirmOpened, setIsConfirmOpened] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  useEffect(() => {
    if (reactQuillRef.current) {
      setCounter(reactQuillRef.current.getEditor().getLength() - 1)
    }
    setValue(sourceValue)
  }, [sourceValue])

  const handleEditClicked = () => {
    if (reactQuillRef.current) {
      setCounter(reactQuillRef.current.getEditor().getLength() - 1)
    }
    setIsEdit(true)
    setIsConfirmOpened(false)
  }

  const handleBlur = () => {
    setIsConfirmOpened(true)
  }

  const handleCancelClicked = () => {
    setValue(sourceValue)
    setIsEdit(false)
    setIsConfirmOpened(false)
  }

  const handleConfirmCancelClicked = () => {
    setIsConfirmOpened(false)
  }

  const handleAsyncConfirm = async () => {
    const isSaved = await onSave(value)
    setIsConfirmOpened(!isSaved)
    setIsEdit(!isSaved)
  }

  const handleChange = (content: string, delta: any, source: any, editor: any) => {
    if (limit && editor.getLength() >= limit) {
      toast.error(`Limit ${limit} symbols exceeded!`)
    } else {
      setValue(content)
      setCounter(editor.getLength() - 1)
      setIsConfirmOpened(true)
    }
  }

  return (
    <div className={styles.root}>
      <Typography.Title level={5}>{title}:</Typography.Title>
      <div className={styles.editorWrapper}>
        <div className={styles.container}>
          <div className={styles.counterWrapper}>
            {isEdit && limit && (<p className={styles.counter}>{counter + `/${limit}`}</p>)}
            {!isEdit && (<Button className={styles.editButton} onClick={handleEditClicked} icon={<EditOutlined />} />)}
          </div>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
            placeholder="Type something..."
            readOnly={!isEdit}
            ref={reactQuillRef}
            onBlur={handleBlur}
            className={styles.quillEditor}
          />
        </div>
        {isEdit && (
          <div className={styles.buttonsContainer}>
            <Popconfirm
              title="Are you sure you want to update this field?"
              open={isConfirmOpened}
              onConfirm={handleAsyncConfirm}
              onCancel={handleConfirmCancelClicked}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary">Save</Button>
            </Popconfirm>
            <Button onClick={handleCancelClicked}>Cancel</Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditableQuillEditorAntd
