import { toast } from 'react-toastify'
import React, { useRef, useState } from 'react'
import { Button, Checkbox, Input, InputRef } from 'antd'

import { authProvider } from 'app/auth/auth-provider'
import { useLoginMutation } from 'app/api/users-api'

import styles from './Login.module.scss'
import { useNavigate } from 'react-router-dom'

type Props = {
  conversationId?: string
}

const Login = ({ conversationId }: Props) => {
  const passwordRef = useRef<InputRef>(null)

  const navigate = useNavigate()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [rememberMe, setRememberMe] = useState<boolean>(false)

  const [login, { isLoading }] = useLoginMutation()

  const handleChangeEmail: React.ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => setEmail(currentTarget.value)
  const handleChangePassword: React.ChangeEventHandler<HTMLInputElement> = ({ currentTarget }) => setPassword(currentTarget.value)
  const handleClickRememberMe = () => setRememberMe(!rememberMe)

  const handleSubmit: React.FormEventHandler<HTMLElement> = async (e) => {
    e.preventDefault()
    passwordRef.current?.focus()
    if (!isLoading && email && password) {
      await login({ email, password })
        .unwrap()
        .then((userData) => {
          authProvider.signIn(userData.token, userData.refreshToken, rememberMe)
          if (conversationId) navigate(`/customers/conversations?id=${conversationId}`)
        })
        .catch((error) => toast.error(error))
    }
  }

  return (
    <div className={styles.container}>
      <form className={styles.content} onSubmit={handleSubmit}>
        <div className={styles.header}>
          <h1>Customer login</h1>
          <div className={styles.logo} />
        </div>
        <Input autoFocus={true} value={email} onChange={handleChangeEmail} placeholder="Email" />
        <Input.Password ref={passwordRef} value={password} onChange={handleChangePassword} placeholder="Password" />
        <Checkbox checked={rememberMe} onClick={handleClickRememberMe}>
          Remember me
        </Checkbox>
        <Button type="primary" htmlType="submit" className={styles.submitButton} loading={isLoading} disabled={isLoading}>
          LOGIN
        </Button>
      </form>
    </div>
  )
}

export default Login
