import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type SupplierProductListQuery = {
  searchText?: string
  page?: number
  size?: number
}

export const supplierOrderDesignsApi = createApi({
  reducerPath: 'supplierOrderDesignsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierOrderDesigns'],

  endpoints: (builder) => ({
    supplierOrderDesign: builder.query<Pto.SupplierOrderDesigns.SupplierOrderDesign, string>({
      query: (designId) => `/supplier-order-designs/${designId}`,
      providesTags: (_result, _error, designId) => getProvidedTags('SupplierOrderDesigns', designId)
    }),
    supplierOrderDesignList: builder.query<Pto.SupplierOrderDesigns.List, SupplierProductListQuery>({
      query: ({ searchText, page, size }) => ({
        url: 'supplier-order-designs',
        params: { searchText, page, size }
      }),
      providesTags: (supplierOrderDesignsListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderDesigns',
          'LIST',
          supplierOrderDesignsListData?.items.map((item) => item.id)
        )
    }),
    updateMockupImage: builder.mutation<void, Pto.SupplierOrderDesigns.SetMockupImageUrl & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/set-mockup-image-url`,
        method: 'PATCH',
        body
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    updateArtworkImage: builder.mutation<void, Pto.SupplierOrderDesigns.SetArtworkImageUrls & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/set-artwork-image-urls`,
        body,
        method: 'PATCH'
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    addCustomizationLine: builder.mutation<void, Pto.SupplierOrderDesigns.AddCustomizationLine & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/add-customization-line`,
        body,
        method: 'POST'
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    removeCustomizationLine: builder.mutation<void, Pto.SupplierOrderDesigns.RemoveCustomizationLine & { designId: string }>({
      query: ({ designId, ...body }) => ({
        url: `/supplier-order-designs/${designId}/remove-customization-line`,
        body,
        method: 'DELETE'
      }),
      invalidatesTags: (_result, _error, { designId }) => [{ type: 'SupplierOrderDesigns', id: designId }]
    }),
    completeDesign: builder.mutation<void, { designId: string }>({
      query: ({ designId }) => ({
        url: `/supplier-order-designs/${designId}/complete`,
        method: 'PATCH'
      })
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order design') {
    store.dispatch(supplierOrderDesignsApi.util.invalidateTags([{ type: 'SupplierOrderDesigns', id: payload.entityId }]))
  }
}

export const subscribeToSupplierOrderDesignEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierOrderDesignEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierOrderDesignListQuery,
  useSupplierOrderDesignQuery,
  useUpdateMockupImageMutation,
  useUpdateArtworkImageMutation,
  useAddCustomizationLineMutation,
  useRemoveCustomizationLineMutation
} = supplierOrderDesignsApi
