import { Pto } from '@merchx-v3/pto'
import { EditOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { Button, Select, Popconfirm, Space, Typography } from 'antd'

import styles from './EditableSelect.module.scss'

type Props = {
  title?: string
  sourceValue: string
  options: Pto.Option[]
  tabIndex?: number
  padding?: boolean
  onSave: (newValue: string) => Promise<boolean>
}

const EditableSelect = (props: Props) => {
  const { sourceValue, tabIndex, title, options, padding = true, onSave } = props

  const [isOpened, setIsOpened] = useState<boolean>(false)

  const [value, setValue] = useState(props.sourceValue)
  const [isConfirmOpened, setIsConfirmOpened] = useState(false)

  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (value !== sourceValue) {
      setIsEdit(false)
      setIsConfirmOpened(false)
      setValue(sourceValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceValue])

  const handleEditClicked = () => {
    setIsEdit(true)
    setIsOpened(true)
    setIsConfirmOpened(false)
  }

  const handleCancelClicked = () => {
    setValue(sourceValue)
    setIsEdit(false)
    setIsConfirmOpened(false)
    setIsOpened(false)
  }

  const handleConfirmCancelClicked = () => {
    setIsOpened(true)
    setIsConfirmOpened(false)
  }

  const handleAsyncConfirm = async () => {
    const isSaved = await onSave(value)
    setIsConfirmOpened(!isSaved)
    setIsEdit(!isSaved)
    setIsOpened(false)
  }

  const handleSelect = (value: string) => {
    setValue(value)
    setIsOpened(false)
    setIsConfirmOpened(true)
  }

  const currentOption = options.filter((option) => option.value === value)

  return (
    <div className={styles.root} data-padding={padding}>
      {title && <Typography.Title level={5}>{title}:</Typography.Title>}
      <Space.Compact>
        <Select
          tabIndex={tabIndex}
          className={styles.select}
          suffixIcon={null}
          open={isOpened}
          onClick={() => setIsConfirmOpened(true)}
          autoFocus
          value={value}
          onSelect={(newValue) => handleSelect(newValue)}
          options={isEdit ? options : currentOption}
        />
        {!isEdit && <Button onClick={handleEditClicked} icon={<EditOutlined />} />}
        {isEdit && <Button onClick={handleCancelClicked}>Cancel</Button>}
        {isEdit && (
          <Popconfirm
            title="Are you sure save this value?"
            open={isConfirmOpened}
            onConfirm={handleAsyncConfirm}
            onCancel={handleConfirmCancelClicked}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Save</Button>
          </Popconfirm>
        )}
      </Space.Compact>
    </div>
  )
}

export default EditableSelect
