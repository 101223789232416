import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { WebSocket } from '@merchx-v3/web-socket'
import { Pto } from '@merchx-v3/pto'

import { store } from 'app/store'
import { settings } from 'config/settings'
import { tokenProvider } from 'app/auth/token-provider'
import { getProvidedTags } from 'helpers/getProvidedTags'

const { protocol, domain } = settings.site

type SupplierOrderShippingsListQuery = {
  searchText?: string
  page?: number
  size?: number
}

type ShippingTaskArgs = {
  shippingId: string
  shippingTaskId: string
}

export const supplierOrderShippingsApi = createApi({
  reducerPath: 'supplierOrderShippingsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${protocol}://${domain}/api`,
    prepareHeaders: tokenProvider.prepareHeaders
  }),
  tagTypes: ['SupplierOrderShippings', 'SupplierOrderShippingTasks'],

  endpoints: (builder) => ({
    supplierOrderShipping: builder.query<Pto.SupplierOrderShippings.SupplierOrderShipping, string>({
      query: (shippingId) => `/supplier-order-shippings/${shippingId}`,
      providesTags: (_result, _error, shippingId) => getProvidedTags('SupplierOrderShippings', shippingId)
    }),

    supplierOrderShippingList: builder.query<Pto.SupplierOrderShippings.List, SupplierOrderShippingsListQuery>({
      query: ({ searchText, page, size }) => ({
        url: 'supplier-order-shippings',
        params: { searchText, page, size }
      }),
      providesTags: (supplierOrderShippingListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderShippings',
          'LIST',
          supplierOrderShippingListData?.items.map((item) => item.id)
        )
    }),
    sendToShipping: builder.mutation<void, string>({
      query: (shippingId) => ({
        url: `/supplier-order-shippings/${shippingId}/send-to-shipping`,
        method: 'POST'
      })
    }),

    startTaskProcessing: builder.mutation<void, ShippingTaskArgs>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/start-processing`,
        method: 'PATCH'
      })
    }),

    completeTask: builder.mutation<void, ShippingTaskArgs>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/complete`,
        method: 'PATCH'
      })
    }),

    cancelTask: builder.mutation<void, ShippingTaskArgs & Pto.SupplierOrderShippings.Commands.Args.CancelTask>({
      query: ({ shippingId, shippingTaskId, reason }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/cancel`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    holdTask: builder.mutation<void, ShippingTaskArgs & Pto.SupplierOrderShippings.Commands.Args.HoldTask>({
      query: ({ shippingId, shippingTaskId, reason }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/hold`,
        method: 'PATCH',
        body: { reason }
      })
    }),

    unholdTask: builder.mutation<void, ShippingTaskArgs>({
      query: ({ shippingId, shippingTaskId }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/unhold`,
        method: 'PATCH'
      })
    }),

    changeTrackingNumber: builder.mutation<void, ShippingTaskArgs & Pto.SupplierOrderShippings.Commands.Args.ChangeTrackingNumber>({
      query: ({ shippingId, shippingTaskId, ...body }) => ({
        url: `/supplier-order-shippings/${shippingId}/shipping-tasks/${shippingTaskId}/tracking-number`,
        method: 'PATCH',
        body
      })
    }),

    getTaskStatusCounts: builder.query<Pto.SupplierOrderShippings.Tasks.TaskStatusCount, void>({
      query: () => ({
        url: `/supplier-order-shipping-tasks/status-counts`,
        method: 'GET'
      }),
      providesTags: ['SupplierOrderShippingTasks']
    }),

    supplierOrderShippingTaskList: builder.query<Pto.SupplierOrderShippings.Tasks.List, Pto.SupplierOrderShippings.Tasks.ListArgs>({
      query: (listArgs) => ({
        url: `supplier-order-shipping-tasks`,
        params: listArgs,
        method: 'GET'
      }),
      providesTags: (shippingTaskListData, _error, _args) =>
        getProvidedTags(
          'SupplierOrderShippingTasks',
          'LIST',
          shippingTaskListData?.items.map((item) => item.supplierOrderId)
        )
    }),

    supplierOrderShippingTask: builder.query<Pto.SupplierOrderShippings.Tasks.Details, string>({
      query: (shippingTaskId) => `/supplier-order-shipping-tasks/${shippingTaskId}`,
      providesTags: (result, _error, _shippingTaskId) => (result ? getProvidedTags('SupplierOrderShippings', result.supplierOrderId) : [])
    })
  })
})

const onEntityUpdated = (payload: WebSocket.Channels.Listeners.EntityUpdatedPayload) => {
  if (payload.entityType === 'Supplier order shipping') {
    store.dispatch(supplierOrderShippingsApi.util.invalidateTags([{ type: 'SupplierOrderShippings', id: payload.entityId }]))
  }
}

export const subscribeToSupplierOrderShippingsEvents = (socket: WebSocket.MxWebSocket, dispatch: Dispatch<AnyAction>) => {
  socket.on('entity-updated', onEntityUpdated)
}

export const unsubscribeFromSupplierOrderShippingsEvents = (socket: WebSocket.MxWebSocket) => {
  socket.off('entity-updated', onEntityUpdated)
}

export const {
  useSupplierOrderShippingListQuery,
  useSupplierOrderShippingQuery,
  useSendToShippingMutation,
  useStartTaskProcessingMutation,
  useCompleteTaskMutation,
  useCancelTaskMutation,
  useHoldTaskMutation,
  useUnholdTaskMutation,
  useChangeTrackingNumberMutation,
  useGetTaskStatusCountsQuery,
  useSupplierOrderShippingTaskListQuery,
  useSupplierOrderShippingTaskQuery
} = supplierOrderShippingsApi
