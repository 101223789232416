import { PlusOutlined } from '@ant-design/icons'
import { Layout as AntdLayout } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useUser } from 'app/auth'
import { Loading } from 'components/Loading'
import { PortalContext } from 'components/App/App'
import { useSupplierContext } from 'components/SupplierContext/context'
import { SupplierContextProvider } from 'components/SupplierContext/provider'
import SiderMenu from '../SiderMenu'
import Header from './Header'

import { AddSupplierCenterModal } from './AddSupplierCenterModal'

import styles from './Layout.module.scss'

type LayoutProps = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, shouldWaitUser = false }) => {
  const [user] = useUser()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/common')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (shouldWaitUser && !user) return <Loading />

  return (
    <SupplierContextProvider>
      <CommonLayout>{children}</CommonLayout>
    </SupplierContextProvider>
  )
}

const CommonLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)

  return (
    <AntdLayout style={{ minHeight: '100vh' }}>
      <AntdLayout.Header className={styles.header}>
        <Header />
      </AntdLayout.Header>
      <AntdLayout>
        <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242} theme="light">
          <SiderMenu collapsed={collapsedMainMenu} />
        </AntdLayout.Sider>
        <AntdLayout.Content>{children}</AntdLayout.Content>
      </AntdLayout>
      {/* <Footer style={footerStyle}>Footer</Footer> */}
    </AntdLayout>
    // <AntdLayout hasSider className={styles.layout}>
    //   <div className={styles.extraMenu}>
    //     {supplierCenters.map((item) => (
    //       <div key={item.id} className={item.id === supplierCenter.id ? styles.extraMenuItemActive : styles.extraMenuItem} onClick={() => setActiveSupplierCenter(item.id)}>
    //         {item.name}
    //       </div>
    //     ))}
    //     <div className={styles.extraMenuItemAddNew} onClick={() => setIsAddSupplierCenterModalVisible(true)}>
    //       <PlusOutlined />
    //     </div>
    //     <AddSupplierCenterModal supplierId={supplierId} isOpened={isAddSupplierCenterModalVisible} onClose={() => setIsAddSupplierCenterModalVisible(false)} />
    //   </div>

    //   <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242}>
    //     <SiderMenu collapsed={collapsedMainMenu} />
    //   </AntdLayout.Sider>
    //   <AntdLayout>
    //     <AntdLayout.Content className={styles.content}>{children}</AntdLayout.Content>
    //   </AntdLayout>
    // </AntdLayout>
  )
}

export default Layout
