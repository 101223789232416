import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar, Badge, Space, Dropdown, Tooltip, MenuProps, Button, Divider } from 'antd'
import { BellOutlined, DownOutlined, PlusOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons'
import { AppRoutes } from '../../Router'
import { AddSupplierCenterModal } from '../AddSupplierCenterModal'
import { useSupplierContext } from 'components/SupplierContext/context'

import { connector, PropsFromRedux } from './container'
import styles from './Header.module.scss'
import { useUser } from 'app/auth'
import { useConversation } from 'app/chat/context/context'

const Header = ({ appVersion }: PropsFromRedux) => {
  const [user] = useUser()
  const { totalUnreadMessages } = useConversation()

  const [isAddSupplierCenterModalVisible, setIsAddSupplierCenterModalVisible] = useState(false)
  const { supplierId, supplierCenter, supplierCenters, setActiveSupplierCenter } = useSupplierContext()

  const onClick: MenuProps['onClick'] = ({ key }) => {
    setActiveSupplierCenter(key)
  }

  const items: MenuProps['items'] = supplierCenters.map((item, index) => ({ key: item.id, label: item.name }))

  const contentStyle: React.CSSProperties = {
    width: 210,
    backgroundColor: '#fff',
    borderRadius: '16px',
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)'
  }

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none'
  }

  return (
    <div className={styles.container}>
      <div className={styles.logoBox}>
        <Tooltip title={`Version ${appVersion}`}>
          <img src="/logoBlack.svg" alt="Merchconnect" className={styles.logo} />
        </Tooltip>

        <Dropdown
          menu={{ items, onClick }}
          dropdownRender={(menu) => (
            <div style={contentStyle}>
              {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
              <Divider style={{ margin: 0 }} />
              <Space style={{ padding: 8, width: '100%' }}>
                <Button type="primary" icon={<PlusOutlined />} className={styles.addBtn} onClick={() => setIsAddSupplierCenterModalVisible(true)}>
                  Add new supplier center
                </Button>
              </Space>
            </div>
          )}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {supplierCenter.name}
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      </div>

      <div className={styles.info}>
        <div className={styles.chatInfo}>
          <Badge count={totalUnreadMessages} className={styles.counterBadge}>
            <Avatar size={40} style={{ backgroundColor: '#F5F7FA', border: 0 }} shape="circle" icon={<BellOutlined style={{ color: '#00000073' }} />} />
          </Badge>
          <Tooltip title="Switch Interface">
            <Link to="/">
              <Avatar size={40} style={{ backgroundColor: '#F5F7FA', border: 0 }} shape="circle" icon={<SyncOutlined style={{ color: '#00000073' }} />} />
            </Link>
          </Tooltip>
        </div>
        <div className={styles.userInfo}>
          <div className={styles.textWrapper}>
            <p className={styles.name}>{`${user?.firstName || ''} ${user?.lastName || ''}`}</p>
            <p className={styles.email}>{user?.email}</p>
          </div>
          <Link to={`./${AppRoutes.profile}`}>
            <Avatar
              size={40}
              style={{ backgroundColor: '#00000026', border: 0 }}
              src={`/images/user-avatars/${user?.id || ''}?width=200&height=200`}
              shape="circle"
              icon={<UserOutlined />}
            />
          </Link>
        </div>
      </div>
      <AddSupplierCenterModal supplierId={supplierId} isOpened={isAddSupplierCenterModalVisible} onClose={() => setIsAddSupplierCenterModalVisible(false)} />
    </div>
  )
}

export default connector(Header)
