import React, { useState, useContext, useEffect } from 'react'
import { Layout as AntdLayout } from 'antd'
import { PortalContext } from 'components/App/App'

import { useUser } from 'app/auth'
import { Loading } from 'components/Loading'
import SiderMenuDesigners from '../SiderMenuDesigners'
import { SupplierContextProvider } from 'components/SupplierContext/provider'

import styles from './Layout.module.scss'

type LayoutProps = {
  shouldWaitUser?: boolean
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({ children, shouldWaitUser = false }) => {
  const [user] = useUser()

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/common')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (shouldWaitUser && !user) return <Loading />

  return (
    <SupplierContextProvider>
      <DesignerLayout>{children}</DesignerLayout>
    </SupplierContextProvider>
  )
}

const DesignerLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [collapsedMainMenu, setCollapsedMainMenu] = useState(false)

  const { setPortal } = useContext(PortalContext)

  useEffect(() => {
    setPortal('/designers')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AntdLayout hasSider className={styles.layout}>
      <AntdLayout.Sider collapsible={true} collapsed={collapsedMainMenu} onCollapse={setCollapsedMainMenu} width={242}>
        <SiderMenuDesigners collapsed={collapsedMainMenu} />
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Content className={styles.content}>{children}</AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  )
}

export default Layout
