import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import dashboardReducer from 'interfaces/admin/features/dashboard/dashboardSlice'
import invoicesReducer from 'interfaces/admin/features/invoices/invoicesSlice'
import ordersReducer from 'interfaces/admin/features/orders/ordersSlice'
import pluginsReducer from 'interfaces/admin/features/plugins/pluginsSlice'

import sagasReducer from 'interfaces/admin/features/sagas/sagasSlice'

import { filesApi } from 'shared/files-api'

import { attributesApi } from './api/attributes-api'
import { cartsApi } from './api/carts-api'
import { categoriesApi } from './api/categories-api'
import { checkoutServicesApi } from './api/checkout-services-api'
import { conversationsApi } from './api/conversations-api'
import { customGatewayBlankProductsApi } from './api/custom-gateway-blank-products-api'
import { customGatewayCategoriesApi } from './api/custom-gateway-categories-api'
import { customGatewayProductsApi } from './api/custom-gateway-products-api'
import { customGatewayProductsDataApi } from './api/custom-gateway-products-data-api'
import { customersApi } from './api/customers-api'
import { customGatewaySettingsApi } from './api/custom-gateway-settings-api'
import { designsApi } from './api/designs-api'
import { designRequestsApi } from './api/design-requests'
import { designTemplatesApi } from './api/design-templates-api'
import { ecomProductsApi } from './api/ecom-products-api'
import { fulfillmentSystemsApi } from './api/fulfillment-systems-api'
import { fulfillmentTasksApi } from './api/fulfillment-tasks-api'
import { notificationsApi } from './api/notifications-api'
import { paymentsApi } from './api/payments-api'
import { pluginsApiV2 } from './api/plugins-api'
import { processingTemplatesApi } from './api/processing-templates-api'
import { productsApi } from './api/products-api'
import { projectsApiV2 } from './api/projects-api'
import { reportingApi } from './api/reporting-api'
import { sagasApi } from './api/sagas-api'
import { shipmentsApi } from './api/shipments-api'
import { shippingSystemsApi } from './api/shipping-systems-api'
import { shipstationSettingsApi } from './api/shipstation-settings-api'
import { supplierOrdersApi } from './api/supplier-orders-api'
import { supplierOrderDesignsApi } from './api/supplier-order-designs-api'
import { supplierOrderFulfillmentsApi } from './api/supplier-order-fulfillments-api'
import { supplierOrderShippingsApi } from './api/supplier-order-shippings-api'
import { supplierProductsApi } from './api/supplier-products-api'
import { suppliersApi } from './api/suppliers-api'
import { tagsApi } from './api/tags-api'
import { techniquesApi } from './api/techniques-api'
import { usersApi } from './api/users-api'
import { webSocketMiddleware } from './websocket-middleware'

import { rtkQueryErrorLogger } from './error-middleware'
import { appWebSocket } from './websocket'

export const store = configureStore({
  reducer: {
    [attributesApi.reducerPath]: attributesApi.reducer,
    [cartsApi.reducerPath]: cartsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [checkoutServicesApi.reducerPath]: checkoutServicesApi.reducer,
    [conversationsApi.reducerPath]: conversationsApi.reducer,
    [customGatewayBlankProductsApi.reducerPath]: customGatewayBlankProductsApi.reducer,
    [customGatewayCategoriesApi.reducerPath]: customGatewayCategoriesApi.reducer,
    [customGatewayProductsApi.reducerPath]: customGatewayProductsApi.reducer,
    [customGatewayProductsDataApi.reducerPath]: customGatewayProductsDataApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [customGatewaySettingsApi.reducerPath]: customGatewaySettingsApi.reducer,
    dashboard: dashboardReducer,
    [designRequestsApi.reducerPath]: designRequestsApi.reducer,
    [designsApi.reducerPath]: designsApi.reducer,
    [ecomProductsApi.reducerPath]: ecomProductsApi.reducer,
    [fulfillmentSystemsApi.reducerPath]: fulfillmentSystemsApi.reducer,
    [filesApi.reducerPath]: filesApi.reducer,
    invoices: invoicesReducer,
    orders: ordersReducer,
    plugins: pluginsReducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [pluginsApiV2.reducerPath]: pluginsApiV2.reducer,
    [fulfillmentTasksApi.reducerPath]: fulfillmentTasksApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [projectsApiV2.reducerPath]: projectsApiV2.reducer,
    [reportingApi.reducerPath]: reportingApi.reducer,
    [sagasApi.reducerPath]: sagasApi.reducer,
    sagas: sagasReducer,
    [shipmentsApi.reducerPath]: shipmentsApi.reducer,
    [shippingSystemsApi.reducerPath]: shippingSystemsApi.reducer,
    [shipstationSettingsApi.reducerPath]: shipstationSettingsApi.reducer,
    [supplierOrdersApi.reducerPath]: supplierOrdersApi.reducer,
    [supplierOrderDesignsApi.reducerPath]: supplierOrderDesignsApi.reducer,
    [supplierOrderFulfillmentsApi.reducerPath]: supplierOrderFulfillmentsApi.reducer,
    [supplierOrderShippingsApi.reducerPath]: supplierOrderShippingsApi.reducer,
    [supplierProductsApi.reducerPath]: supplierProductsApi.reducer,
    [suppliersApi.reducerPath]: suppliersApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [techniquesApi.reducerPath]: techniquesApi.reducer,
    [designTemplatesApi.reducerPath]: designTemplatesApi.reducer,
    [processingTemplatesApi.reducerPath]: processingTemplatesApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(webSocketMiddleware(appWebSocket))
      .concat(attributesApi.middleware)
      .concat(cartsApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(checkoutServicesApi.middleware)
      .concat(conversationsApi.middleware)
      .concat(customGatewayBlankProductsApi.middleware)
      .concat(customGatewayProductsApi.middleware)
      .concat(customGatewayProductsDataApi.middleware)
      .concat(customGatewayCategoriesApi.middleware)
      .concat(customGatewaySettingsApi.middleware)
      .concat(filesApi.middleware)
      .concat(designRequestsApi.middleware)
      .concat(designsApi.middleware)
      .concat(paymentsApi.middleware)
      .concat(ecomProductsApi.middleware)
      .concat(fulfillmentSystemsApi.middleware)
      .concat(pluginsApiV2.middleware)
      .concat(suppliersApi.middleware)
      .concat(projectsApiV2.middleware)
      .concat(reportingApi.middleware)
      .concat(fulfillmentTasksApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(sagasApi.middleware)
      .concat(shipmentsApi.middleware)
      .concat(shippingSystemsApi.middleware)
      .concat(shipstationSettingsApi.middleware)
      .concat(supplierOrdersApi.middleware)
      .concat(supplierOrderDesignsApi.middleware)
      .concat(supplierOrderFulfillmentsApi.middleware)
      .concat(supplierOrderShippingsApi.middleware)
      .concat(supplierProductsApi.middleware)
      .concat(tagsApi.middleware)
      .concat(techniquesApi.middleware)
      .concat(designTemplatesApi.middleware)
      .concat(processingTemplatesApi.middleware)
      .concat(productsApi.middleware)
      .concat(customersApi.middleware)
      .concat(usersApi.middleware)
})

void setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
