import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Pto, Role } from '@merchx-v3/pto'
import { Menu, MenuProps } from 'antd'
import {
  BgColorsOutlined,
  CameraOutlined,
  LogoutOutlined,
  PictureOutlined,
  ProfileOutlined,
  ProjectOutlined,
  RocketOutlined,
  SettingOutlined,
  SignatureOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SkinOutlined,
  TeamOutlined,
  TruckOutlined
} from '@ant-design/icons'

import { useUser } from 'app/auth'
import { authProvider } from 'app/auth/auth-provider'
import { AppRoutes } from '../Router'

import styles from './SiderMenu.module.scss'
import { useSupplierContext } from 'components/SupplierContext/context'

type Props = {
  collapsed: boolean
  selectedSupplierCenter?: Pto.Suppliers.SupplierCenter
}

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem => {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem
}

const SiderMenu = ({ collapsed, selectedSupplierCenter }: Props) => {
  const location = useLocation()
  const [user] = useUser()

  const { supplierId } = useSupplierContext()

  const [currentPath, setCurrentPath] = useState<string[]>(location.pathname.split('/'))
  const [openKeys, setOpenKeys] = useState<string[]>([])

  const submenus = ['customers', 'legacy']

  useEffect(() => {
    const openedKey = currentPath.filter((path) => submenus.indexOf(path) !== -1)
    setOpenKeys(openedKey)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMenuClick = (e: any) => {
    if (currentPath !== e.key) {
      setCurrentPath(e.key)
    }
  }

  const onOpenChange = (items: string[]) => {
    setOpenKeys(items)
  }

  let menu: MenuItem[] = [
    getItem(<Link to={`./${AppRoutes.supplierOrders}`}>Orders</Link>, AppRoutes.supplierOrders, <ShoppingOutlined />),
    getItem(<Link to={`./${AppRoutes.supplierOrderDesigns}`}>Designs</Link>, AppRoutes.supplierOrderDesigns, <SignatureOutlined />),
    getItem(<Link to={`./${AppRoutes.supplierOrderFulfillments}`}>Fulfillments</Link>, AppRoutes.supplierOrderFulfillments, <BgColorsOutlined />),
    getItem(<Link to={`./${AppRoutes.supplierOrderFulfillmentTasks}`}>Fulfillment Tasks</Link>, AppRoutes.supplierOrderFulfillmentTasks, <ProfileOutlined />),
    getItem(<Link to={`./${AppRoutes.supplierOrderShippings}`}>Shippings</Link>, AppRoutes.supplierOrderShippings, <TruckOutlined />),
    getItem(<Link to={`./${AppRoutes.supplierOrderShippingTasks}`}>Shipping Tasks</Link>, AppRoutes.supplierOrderShippingTasks, <TruckOutlined />),
    getItem(<Link to={`./${AppRoutes.products}`}>Products V2</Link>, AppRoutes.products, <SkinOutlined />),
    getItem(<Link to={`./${AppRoutes.carts}`}>Carts</Link>, AppRoutes.carts, <ShoppingCartOutlined />),

    getItem('Customers', 'customers', <TeamOutlined />, [
      getItem(<Link to={`./${AppRoutes.customers}`}>List</Link>, AppRoutes.customers),
      getItem(<Link to={`./${AppRoutes.customerReport}`}>Report</Link>, AppRoutes.customerReport)
    ]),
    getItem('Legacy', 'legacy', <TeamOutlined />, [
      getItem(<Link to={`./${AppRoutes.legacyEcomProducts}`}>Ecom Products</Link>, AppRoutes.legacyEcomProducts, <SkinOutlined />),
      getItem(<Link to={`./${AppRoutes.legacySupplierProducts}`}>Supplier Products</Link>, AppRoutes.legacySupplierProducts, <SkinOutlined />),
      getItem(<Link to={`./${AppRoutes.legacyDesign}`}>Design</Link>, AppRoutes.legacyDesign, <CameraOutlined />),
      getItem(<Link to={`./${AppRoutes.legacyProjects}`}>Projects</Link>, AppRoutes.legacyProjects, <ProjectOutlined />),
      getItem(<Link to={`./${AppRoutes.legacyDesignRequest}/me`}>My Design Requests</Link>, 'my-design-requests', <PictureOutlined />),
      getItem(<Link to={`./${AppRoutes.legacyDesignRequest}`}>Design Requests</Link>, AppRoutes.legacyDesignRequest, <PictureOutlined />),
      getItem(<Link to={`./${AppRoutes.legacyFulfillment}`}>Fulfillment</Link>, AppRoutes.legacyFulfillment, <BgColorsOutlined />),
      getItem(<Link to={`./${AppRoutes.legacyShipping}`}>Shipping</Link>, AppRoutes.legacyShipping, <RocketOutlined />)
    ]),

    getItem(<Link to={`./${AppRoutes.settings}/${supplierId}`}>Settings</Link>, AppRoutes.settings, <SettingOutlined />),
    getItem(<span onClick={() => authProvider.signOut()}>Logout</span>, 'logout', <LogoutOutlined />)
  ]

  let menuItems = [] as MenuItem[]
  if (user?.roles.includes(Role.SystemAdmin)) {
    menuItems = menu
  }
  if (user?.roles.includes(Role.User)) {
    menuItems = menu
  }

  return (
    <div className={styles.container}>
      {!collapsed && <div className={styles.supplierCenter}>{selectedSupplierCenter ? selectedSupplierCenter.name : ''}</div>}
      <Menu selectedKeys={currentPath} openKeys={openKeys} mode="inline" theme="light" items={menuItems} onOpenChange={onOpenChange} onClick={handleMenuClick} />
    </div>
  )
}

export default SiderMenu
