'use client'

import { ReactNode, Suspense, useCallback, useEffect, useRef, useTransition } from 'react'
import { Pto } from '@merchx-v3/pto'
import { useImmer } from 'use-immer'

import { SupplierContext } from './context'
import { useSupplierCentersBySupplierQuery, useSupplierQuery } from 'app/api/suppliers-api'
import { Loading } from 'components/Loading'
import { NotFoundPage } from 'components'

export type ProviderProps = {
  // supplierCenters: Pto.Suppliers.SupplierCenter[]

  children: ReactNode
}

export interface SupplierContextProps {
  supplierId: string
  supplierCenterId: string

  supplier: Pto.Suppliers.Supplier
  supplierCenter: Pto.Suppliers.SupplierCenter
  supplierCenters: Pto.Suppliers.SupplierCenter[]

  setActiveSupplierCenter: (supplierCenterId: string) => void
}

type LocalState = {
  isLoading: boolean
  supplier?: Pto.Suppliers.Supplier
  supplierCenter?: Pto.Suppliers.SupplierCenter

  supplierCenters: Pto.Suppliers.SupplierCenter[]
}

export const SupplierContextProvider = ({ children }: ProviderProps): JSX.Element => {
  const supplierId = '28840339-660d-4a0b-b804-cf8f99e94d90'
  const { data: supplier, isLoading: isSupplierLoading } = useSupplierQuery(supplierId, { skip: !supplierId })
  const { data: supplierCenters = [], isLoading: isSupplierCentersLoading } = useSupplierCentersBySupplierQuery(supplierId)

  const [localState, setLocalState] = useImmer<LocalState>({
    isLoading: isSupplierCentersLoading || isSupplierLoading,
    supplier: undefined,
    supplierCenter: undefined,
    supplierCenters: []
  })

  useEffect(() => {
    setLocalState((draft) => {
      draft.supplierCenters = supplierCenters
      draft.supplierCenter = supplierCenters.length ? supplierCenters[0] : undefined
    })
  }, [setLocalState, supplierCenters])

  useEffect(() => {
    setLocalState((draft) => {
      draft.supplier = supplier
    })
  }, [setLocalState, supplier])

  useEffect(() => {
    setLocalState((draft) => {
      draft.isLoading = isSupplierLoading || isSupplierCentersLoading
    })
  }, [isSupplierLoading, isSupplierCentersLoading, setLocalState])

  const setActiveSupplierCenter = useCallback(
    (supplierCenterId: string) => {
      setLocalState((draft) => {
        draft.supplierCenter = draft.supplierCenters.find((item) => item.id === supplierCenterId)
      })
    },
    [setLocalState]
  )

  if (localState.isLoading) return <Loading />

  if (!localState.supplier || !localState.supplierCenter) return <NotFoundPage />

  return (
    <SupplierContext.Provider
      value={{
        supplierId: localState.supplier.id,
        supplierCenterId: localState.supplierCenter.id,

        supplier: localState.supplier,
        supplierCenter: localState.supplierCenter,
        supplierCenters: localState.supplierCenters,

        setActiveSupplierCenter
      }}
    >
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </SupplierContext.Provider>
  )
}
